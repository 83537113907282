<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">اضافه کردن کاربر جدید</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(createUser)"
          @reset.prevent="resetForm"
        >
          <!-- Family Name -->
          <validation-provider
            #default="validationContext"
            name="familyName"
            rules="required"
          >
            <b-form-group label="نام و نام خانوادگی" label-for="familyName">
              <b-form-input
                id="familyName"
                v-model="data.fullName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="username"
            rules="required|alpha-num"
          >
            <b-form-group label="نام کاربری" label-for="username">
              <b-form-input
                id="username"
                v-model="data.userName"
                :state="getValidationState(validationContext)"
                trim
              />
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <!-- eslint-disable -->
          <validation-provider
            #default="validationContext"
            name="username"
            rules="required"
          >
            <b-form-group label="سطح دسترسی" label-for="type">
              <v-select
                v-model="data.userRole"
                :options="typeOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="type"
              />
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="username"
            rules="required"
          >
            <b-form-group label="جنسیت" label-for="sexuality">
              <v-select
                v-model="data.sexuality"
                :options="typeOptionsSexuality"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="sexuality"
              />
            </b-form-group>
          </validation-provider>

          <!-- mobile -->
          <validation-provider
            #default="validationContext"
            name="Username"
            rules="required|integer"
          >
            <b-form-group label="تلفن همراه" label-for="mobile">
              <b-form-input
                id="mobile"
                v-model="data.mobile"
                :state="getValidationState(validationContext)"
                trim
              />
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="email"
          >
            <b-form-group label="ایمیل" label-for="mail">
              <b-form-input
                id="mail"
                v-model="data.mail"
                :state="getValidationState(validationContext)"
                trim
              />
            </b-form-group>
          </validation-provider>

          <!-- Password -->
          <validation-provider
            #default="validationContext"
            name="password"
            rules="required"
          >
            <b-form-group label="رمز عبور" label-for="password">
              <b-form-input
                id="password"
                v-model="data.password"
                :state="getValidationState(validationContext)"
                trim
              />
            </b-form-group>
          </validation-provider>

          <!-- nationalId -->
          <validation-provider
            #default="validationContext"
            name="nationalId"
            rules="required|integer"
          >
            <b-form-group label="کد ملی" label-for="nationalId">
              <b-form-input
                id="nationalId"
                v-model="data.nationalId"
                :state="getValidationState(validationContext)"
                trim
              />
            </b-form-group>
          </validation-provider>

          <!-- instagramId -->
          <validation-provider
            #default="validationContext"
            name="nationalId"
            rules=""
          >
            <b-form-group label="اینستاگرام" label-for="instagramId">
              <b-form-input
                id="instagramId"
                v-model="data.instagramId"
                :state="getValidationState(validationContext)"
                trim
              />
            </b-form-group>
          </validation-provider>

          <!-- introducerCode -->
          <validation-provider
            #default="validationContext"
            name="introducerCode"
            rules=""
          >
            <b-form-group label="کد معرف" label-for="mobile">
              <b-form-input
                id="introducerCode"
                v-model="data.introducerCode"
                :state="getValidationState(validationContext)"
                trim
              />
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              اضافه کردن
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              انصراف
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "@/@fake-db/data/other/countries";
import store from "@/store";
import { UserCreateRequest } from "@/libs/Api/Users";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { AccountAddUserByAdminRequest } from "@/libs/Api/Account";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,

    // Form Validation
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      typeOptionsSexuality: [
        {
          label: "مرد",
          value: 1,
        },
        {
          label: "زن",
          value: 2,
        },
      ],
      typeOptions: [
        {
          label: "کاربر عادی",
          value: 1,
        },
        {
          label: "بلاگر",
          value: 3,
        },
        {
          label: "صاحب محصول",
          value: 4,
        },
      ],
      required,
      alphaNum,
      // email,
      countries,
      data: {
        fullName: "",
        mail: "",
        mobile: "",
        encryptedMail: "",
        type: 1,
        status: 1,
        password: "",
        userName: "",
        nationalId: "",
        instagramId: "",
        sexuality: 1,
        userCode: "",
        introducerCode: " ",
        verifyCode: 0,
        userRole: 1,
      },
    };
  },
  methods: {
    createUser() {
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          let _this = this;

          let accountAddUserByAdminRequest = new UserCreateRequest(_this);
          accountAddUserByAdminRequest.setParams(_this.data);
          accountAddUserByAdminRequest.fetch(
            function (response) {
              if (response.isSuccess) {
                _this.$emit("refetch-data");
                _this.$emit("update:is-add-new-user-sidebar-active", false);
                _this.$toast({
                  component: ToastificationContent,
                  position: "bottom-center",
                  props: {
                    title: `عملیات موفق`,
                    icon: "CheckIcon",
                    variant: "success",
                    text: `کاربر جدید اضافه شد.`,
                  },
                });
                // if (content === 3) {
                //   return _this.$toast({
                //     component: ToastificationContent,
                //     position: "bottom-left",
                //     props: {
                //       variant: "danger",
                //       text: "نام کاربری یا رمز عبور اشتباه است.",
                //     },
                //   });
                // } else if (content === 4) {
                //   return _this.$toast({
                //     component: ToastificationContent,
                //     position: "bottom-left",
                //     props: {
                //       variant: "danger",
                //       text: "کد ارسال شده منتقضی شده است،لطفا دوباره امتحان کنید.",
                //     },
                //   });
                // } else if (content === 5) {
                //   return _this.$toast({
                //     component: ToastificationContent,
                //     position: "bottom-left",
                //     props: {
                //       variant: "danger",
                //       text: "کد وارد شده اشتباه است.",
                //     },
                //   });
                // } else if (content === 6) {
                //   return _this.$toast({
                //     component: ToastificationContent,
                //     position: "bottom-left",
                //     props: {
                //       variant: "danger",
                //       text: "کاربری با این نام کاربری وجود ندارد.",
                //     },
                //   });
                // } else if (content === 8) {
                //   return _this.$toast({
                //     component: ToastificationContent,
                //     position: "bottom-left",
                //     props: {
                //       variant: "danger",
                //       text: "قبلا کاربری با این نام کاربری ثبت نام کرده است.",
                //     },
                //   });
                // } else if (content === 9) {
                //   return _this.$toast({
                //     component: ToastificationContent,
                //     position: "bottom-left",
                //     props: {
                //       variant: "danger",
                //       text: "پسورد شما ایمن نیست.",
                //     },
                //   });
                // } else if (content === 10) {
                //   return _this.$toast({
                //     component: ToastificationContent,
                //     position: "bottom-left",
                //     props: {
                //       variant: "danger",
                //       text: "نام کاربری قبلا ثبت نام کرده است.",
                //     },
                //   });
                // } else if (content === 11) {
                //   return _this.$toast({
                //     component: ToastificationContent,
                //     position: "bottom-left",
                //     props: {
                //       variant: "danger",
                //       text: "اکانت شما غیرفعال شده است.",
                //     },
                //   });
                // }
              }
            },
            function (error) {
              console.log(error);
            }
          );
        }
      });
    },
    // async validateUsername(event) {
    //   this.payload.userName = event.target.value;
    //   try {
    //     const response =
    //         await this.$repositories.validateUsername.validateUsername(
    //             this.payload.userName
    //         );
    //     this.isUsernameAvailable = response.data;
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
  },
  mounted() {
    // console.log(this.roleOptions)
  },
  setup(props, { emit }) {
    const blankUserData = {
      fullName: "",
      username: "",
      email: "",
      role: null,
      currentPlan: null,
      company: "",
      country: "",
      contact: "",
    };

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const onSubmit = () => {
      store.dispatch("app-user/addUser", userData.value).then(() => {
        emit("refetch-data");
        emit("update:is-add-new-user-sidebar-active", false);
      });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
